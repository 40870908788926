import { Directive, ElementRef, OnDestroy } from '@angular/core';

import { Subscription, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { LayoutService } from '../layout.service';

import { environment } from '@env/environment';

@Directive({
  selector: '[portalHeaderTitle]'
})
export class PortalHeaderTitleDirective implements OnDestroy {
  titleSubscription: Subscription;

  constructor(private layoutService: LayoutService, private translate: TranslateService, private el: ElementRef) {
    const changeLanguage$ = this.translate.onLangChange.pipe(
      startWith(environment.user.lang),
      map(e => environment.user.lang)
    );

    const title$ = this.layoutService.getTitle();

    this.titleSubscription = combineLatest([changeLanguage$, title$]).subscribe(([lang, title]) =>
      this.translate.get(title).subscribe((res: string) => {
        this.el.nativeElement.innerHTML = res;
      })
    );
  }

  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
  }
}
