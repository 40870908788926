import { Injectable } from '@angular/core';

import { Observable, from as observableFrom, of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { ApiService } from './api.service';
import { UserService } from '../authentication/user.service';

import { environment } from '@env/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  requestWithHeaders(req: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;
    if (req.url.includes(environment.apiUrl)) {
      headers = req.headers.set('Authorization', token);
    }
    const headReq = req.clone({ headers: headers });
    return next.handle(headReq);
  }

  private getHeaders(): Promise<any> {
    const token = this.apiService.getIdToken();

    return Promise.all([token]).catch(err => {
      this.userService.logout().then(() => this.router.navigate(['external/login']));
    });
  }

  constructor(private router: Router, private apiService: ApiService, private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.userService.isLoggedIn()) {
      return observableFrom(this.getHeaders()).pipe(
        mergeMap(([jwtToken]: any) => this.requestWithHeaders(req, next, jwtToken))
      );
    } else {
      return next.handle(req);
    }
  }
}
