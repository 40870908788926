import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ApiService } from '@app/core/http/api.service';

import { environment } from '@env/environment';
import { ConstantPool } from '@angular/compiler';

@Injectable()
export class BucketService {
  private AWS = require('aws-sdk');
  private headers: Headers;

  constructor(public _http: HttpClient, public apiService: ApiService) {}

  getPresignedUrl(albumName, fileName, file) {
    const fileInfo = {
      album: albumName,
      fileName: fileName,
      type: file.type
    };
    const params = new HttpParams({ fromObject: fileInfo });

    return this.apiService.get('files/url_upload', params);
  }

  getFile(albumName, fileName): any {
    const fileInfo = {
      album: albumName,
      file: fileName
    };
    const params = new HttpParams({ fromObject: fileInfo });

    return this.apiService.get('files/url_download', params);
  }

  getFilePost(albumName, fileName): any {
    const fileInfo = {
      album: albumName,
      file: encodeURI(fileName)
    };
    
    return this.apiService.post('files/url_download', fileInfo);
  }

  deleteFile(albumName, fileName) {
    const fileInfo = {
      album: albumName,
      file: fileName
    };
    const params = new HttpParams({ fromObject: fileInfo });

    return this.apiService.delete('files', params);
  }

  uploadFileAWSS3(url, file) {
    return this._http.put(url, file);
  }

  uploadJPGAWSS3(url, file) {
    let params = { key: url, uri: file };
    return this.apiService.post('files/jpg_upload', params);
  }

  downloadFileAWSS3(url, filename) {
    return this._http.get(url, { responseType: 'blob' }).subscribe(res => {
      var url = window.URL.createObjectURL(res);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    });
  }
}
