import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './user.service';

interface UserStatus {
  Enabled: boolean;
  UserStatus: string;
  profile: string;
}

@Injectable()
export class AuthGuardService implements CanActivate {
  private auth;

  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url: string = state.url;
    if (url != '/external/login' && this.userService.isLoggedIn()) {
      return this.userService.getOwnStatus().pipe(
        map((auth: UserStatus) => {
          if (auth.Enabled && auth.UserStatus === 'CONFIRMED') {
            if (route.data.roles && route.data.roles.indexOf(auth.profile) === -1) {
              this.router.navigate(['pages/home']);
              return false;
            }
            return true;
          } else {
            this.router.navigate(['external/login']);
            return false;
          }
        })
      );
    } else if (url != '/external/login' && !this.userService.isLoggedIn()) {
      this.router.navigate(['external/login']);
      return false;
    } else {
      if (!this.userService.isLoggedIn()) {
        return true;
      } else {
        this.router.navigate(['pages/home']);
        return false;
      }
    }
  }
}
