import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/authentication/user.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';

import { LanguageService } from '@app/core/language.service';

@Component({
  selector: 'portal-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent implements OnInit {
  public sessionLanguage: string;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.sessionLanguage = environment.user.lang;
  }

  selectLanguage(lang: string): void {
    this.languageService.setLanguage(lang);
    this.sessionLanguage = lang;
  }
}
