import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@app/core/authentication/user.service';

@Component({
  selector: 'portal-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  public user = null;

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getMyUserData().subscribe(data => {
      this.user = { name: data.body.name + ' ' + data.body.last_name, code: data.body.user_code };
    });
  }

  onSignOut() {
    this.userService.logout().then(() => {
      this.router.navigate(['external/login']);
    });
  }
}
