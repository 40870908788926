export const baseEnvironment = {
  cognito: {
    Region: 'eu-west-1',
    UserPoolId: 'eu-west-1_BQdP9zfuI',
    ClientId: '41hkhmpt9j0sf84t3hojpmtii6'
  },
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'es-ES'],

  user: {
    role: null,
    name: null,
    sms: null,
    id: null,
    lang: null,
    user_code: null
  },

  filter: {
    tbt: { order: <any>{}, filters: <any>{} },
    checklist: { order: <any>{}, filters: <any>{} },
    work_permit: { order: <any>{}, filters: <any>{} }
  }
};
