import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared';

import { AuthService } from './authentication/auth.service';
import { UserService } from './authentication/user.service';
import { ApiService } from './http/api.service';

@NgModule({
  imports: [HttpClientModule, RouterModule, SharedModule],
  providers: [UserService, AuthService, ApiService]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(parentModule + ` has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
