import { LayoutService } from './layout.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SharedModule } from '../shared/shared.module';

// Layouts
import { LayoutToolbarComponent } from './layout-toolbar/layout-toolbar.component';

// Components
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MenuSidenavComponent } from './components/menu-sidenav/menu-sidenav.component';
import { LanguageMenuComponent } from './components/language-menu/language-menu.component';
import { PortalHeaderTitleDirective } from './directives/portal-header-title.directive';
import { LayoutLoaderComponent } from './components/layout-loader/layout-loader.component';
import { TopHorizontalMenuComponent } from './components/top-horizontal-menu/top-horizontal-menu.component';

// Directives.
import { PortalScrollTopDirective } from './directives/portal-scroll-top.directive';

import { ModalLayoutComponent } from './components/modal-layout/modal-layout.component';
import { FilterComponent } from './components/filter/filter.component';
import { SelectComponent } from './components/select/select.component';

@NgModule({
  imports: [SharedModule, RouterModule, NgxMatSelectSearchModule],
  declarations: [
    LayoutToolbarComponent,
    UserMenuComponent,
    MenuSidenavComponent,
    LanguageMenuComponent,
    PortalHeaderTitleDirective,
    PortalScrollTopDirective,
    TopHorizontalMenuComponent,
    LayoutLoaderComponent,
    ModalLayoutComponent,
    FilterComponent,
    SelectComponent
  ],
  exports: [ModalLayoutComponent, FilterComponent, SelectComponent],
  providers: [LayoutService]
})
export class LayoutsModule {}
