import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

import { UserService } from '@app/core/authentication/user.service';

import es from '../../assets/i18n/es.json';
import en from '../../assets/i18n/en.json';

import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(private userService: UserService, private translate: TranslateService) {}

  init() {
    const sessionLanguage = this.getSessionLanguage();
    this.translate.setTranslation('en', en);
    this.translate.setTranslation('es', es);
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang(sessionLanguage);
    this.translate.use(sessionLanguage);
  }

  getSessionLanguage() {
    if (environment.user.lang === null) {
      environment.user.lang = navigator.language.split('-')[0];
    }

    this.translate.use(environment.user.lang);
    return environment.user.lang;
  }

  setSessionLanguage() {
    this.translate.use(environment.user.lang);
    moment.locale(environment.user.lang);
  }

  setLanguage(lang: string) {
    this.userService.setLanguage(lang).subscribe();
    environment.user.lang = lang;
    this.setSessionLanguage();
  }
}
