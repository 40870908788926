import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule, PreloadAllModules } from '@angular/router';

import { LayoutToolbarComponent } from './layouts/layout-toolbar/layout-toolbar.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages/home',
    pathMatch: 'full'
  },
  {
    path: '',
    data: {
      base: true
    },
    component: LayoutToolbarComponent,
    children: [
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      }
    ]
  },
  /*  canActivate: [AuthGuardService] */
  /*  }, */
  {
    path: 'external',
    loadChildren: () => import('./external-pages/external-pages.module').then(m => m.ExternalPagesModule)
  },
  {
    path: '**',
    redirectTo: '/external/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  entryComponents: [LayoutToolbarComponent]
})
export class AppRoutingModule {
  constructor(private router: Router) {}
}
