import { MatPaginatorIntl, MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  public ofSize;

  static isLastElement(paginator: MatPaginator): boolean {
    if (paginator.pageIndex !== 0) {
      if (paginator.length % paginator.pageSize === 1) {
        if (Math.floor(paginator.length / paginator.pageSize) === paginator.pageIndex) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  constructor(private readonly translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate
      .get(['ITEMS_PER_PAGE_LABEL', 'NEXT_PAGE_LABEL', 'PREVIOUS_PAGE_LABEL', 'OF'])
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['ITEMS_PER_PAGE_LABEL'];
        this.nextPageLabel = translation['NEXT_PAGE_LABEL'];
        this.previousPageLabel = translation['PREVIOUS_PAGE_LABEL'];
        this.ofSize = translation['OF'];
        this.changes.next();
      });
  }

  getRangeLabel = function(page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 ' + this.ofSize + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + this.ofSize + ' ' + length;
  };
}
