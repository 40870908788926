import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class InfoService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  // Muestra notificaciones al usuario
  displayInfo(message: string, message_aux?: string): void {
    let msg_aux = message_aux ? this.translate.instant(message) + '\n' + message_aux : this.translate.instant(message);
    this.snackBar.open(msg_aux, 'OK', { duration: 2000 });
  }

  displayInfoCustomTime(message: string, time: number): void {
    let msg_aux = this.translate.instant(message);
    this.snackBar.open(msg_aux, 'OK', { duration: time });
  }
}
