import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Observable } from 'rxjs';

import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { environment } from '../../../environments/environment';

import { AuthService } from './auth.service';
import { ApiService } from '../http/api.service';

import { map } from 'rxjs/operators';

var aws = require('aws-sdk');

@Injectable()
export class UserService {
  private userPool = null;

  constructor(
    private router: Router,
    private location: Location,
    public apiService: ApiService,
    private authService: AuthService
  ) {
    const poolData = {
      UserPoolId: environment.cognito.UserPoolId,
      ClientId: environment.cognito.ClientId
    };
    this.userPool = new CognitoUserPool(poolData);
  }

  user() {
    var aux = this.userPool.getCurrentUser();
    if (aux != null) {
      return this.userPool.getCurrentUser();
    } else {
      this.location.replaceState('/');
      this.router.navigate(['login']);
    }
  }

  getMyUserData(): Observable<any> {
    return this.apiService.get('users/own');
  }

  getOwnStatus(): any {
    return this.apiService.get('auth/personal/status').pipe(
      map(data => {
        return {
          Enabled: data.body.Enabled,
          UserStatus: data.body.UserStatus,
          profile: data.body.UserAttributes[2].Value
        };
      })
    );
  }

  username() {
    const user = this.user();
    return user ? user.getUsername() : '';
  }

  isLoggedIn(): boolean {
    return this.userPool.getCurrentUser() !== null;
  }

  logout(): Promise<void> {
    const cognitoUser = this.userPool.getCurrentUser();
    cognitoUser.signOut();
    this.authService.authState$.next(false);
    localStorage.removeItem('idtk');
    return Promise.resolve();
  }

  setLanguage(langCode) {
    var mail = this.user().getUsername();
    return this.apiService.put('users/language', { mail, langCode });
  }
}
