import { Injectable, Injector, ErrorHandler, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { InfoService } from '../../shared/services/info.service';
import { UserService } from '../authentication/user.service';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  error: any;
  debounceTime = 1000;
  errors: Subject<any> = new Subject<any>();

  constructor(private injector: Injector) {
    this.errors.pipe(debounceTime(this.debounceTime)).subscribe(error => this.sendError(error));
  }

  sendError(error: any) {
    this.error = error;
    const zone: NgZone = this.injector.get(NgZone);
    zone.run(() => {
      const infoService = this.injector.get(InfoService);
      const userService = this.injector.get(UserService);
      const router = this.injector.get(Router);

      if (error.status === 403) {
        infoService.displayInfo('FORBIDEN_ERROR');
      } else if (error.status === 401) {
        infoService.displayInfo('UNAUTH_ERROR');
        userService
          .logout()
          .then(() => {
            router.navigate(['external/login']);
          })
          .catch(err => {
            console.log('ERROR EN LOGOUT');
          });
      } else if (error.status === 500) {
        infoService.displayInfo('INTERNAL_ERROR');
      } else if (error.status === 409) {
        infoService.displayInfo(error.error.message);
      }
    });
  }

  handleError(error) {
    if (!environment.production) console.log(error);
    this.errors.next(error);
  }
}
