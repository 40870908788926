import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TokenInterceptor } from './core/http/token-interceptor';

import { AuthGuardService } from './core/authentication/auth-guard.service';
import { CustomErrorHandler } from './core/http/custom-error-handler';

import { CoreModule } from '@app/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutsModule } from './layouts/layouts.module';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { LanguageService } from './core/language.service';
import { DateAdapter } from '@angular/material/core';
import { CustomDatePickerAdapter } from './core/custom-date-picker-adapter';

export function getLocaleId(appLanguageService: LanguageService) {
  const sessionLanguage = appLanguageService.getSessionLanguage();
  return sessionLanguage;
}

registerLocaleData(localeEs, localeEsExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    LayoutsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule // Must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    AuthGuardService,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useFactory: getLocaleId, deps: [LanguageService] },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
