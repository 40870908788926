<mat-toolbar class="portal-toolbar mat-elevation-z6" [ngClass]="{ 'portal-transparent-toolbar': transparent }">
  <mat-toolbar-row>
    <button *ngIf="showMenuIcon()" mat-icon-button (click)="leftSidenav.open()">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button routerLink="/pages/home">
      <mat-icon>home</mat-icon>
    </button>
    <span portalHeaderTitle></span>
    <span fxFlex></span>
    <portal-user-menu></portal-user-menu>
    <portal-language-menu></portal-language-menu>
  </mat-toolbar-row>
</mat-toolbar>
