import { Injectable } from '@angular/core';

import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

import { Subject, BehaviorSubject } from 'rxjs';

import { ApiService } from '@app/core/http/api.service';

import { environment } from '@env/environment';

@Injectable()
export class AuthService {
  private userPool = null;
  authState$: Subject<boolean>;
  private AmazonCognitoIdentity = require('amazon-cognito-identity-js');
  private AWS = require('aws-sdk');
  public ath;

  constructor(public apiService: ApiService) {
    const poolData = {
      UserPoolId: environment.cognito.UserPoolId,
      ClientId: environment.cognito.ClientId
    };

    this.userPool = new CognitoUserPool(poolData);
    this.ath = null;
    this.authState$ = new BehaviorSubject<boolean>(this.userPool.getCurrentUser() !== null);
  }

  login(username: string, password: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool
    });

    this.ath = new AuthenticationDetails({
      Username: username,
      Password: password
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(this.ath, {
        onSuccess: result => {
          this.authState$.next(true);
          resolve();
        },
        onFailure: err =>
          reject({
            reason: 'error',
            err: err
          }),

        newPasswordRequired: (userAttributes, requiredAttributes) =>
          reject({
            reason: 'newPasswordRequired',
            cognitoUser: cognitoUser,
            userAttributes: userAttributes,
            requiredAttributes: requiredAttributes
          }),
        mfaRequired: details =>
          reject({
            reason: 'mfaRequired',
            cognitoUser: cognitoUser,
            details: details
          })
      });
    });
  }

  createUser(user): any {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function(err, session) {
        if (err) return;
      });
      return this.apiService.post('auth', user);
    }
  }

  updateRole(role, mail) {
    return this.apiService.put('auth/role', { role: role, mail: mail });
  }

  confirmUser(mail: string, pass: string, code: string) {
    const cognitoUser = new CognitoUser({ Username: mail, Pool: this.userPool });
    cognitoUser.confirmRegistration(code, false, function(err, result) {
      if (err) {
        return;
      }
    });
    this.login(mail, pass);
  }

  completeNewPasswordChallenge(
    cognitoUser: CognitoUser,
    newPassword: string,
    requiredAttributeData: any
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributeData, {
        onSuccess: () => {
          resolve();
        },
        onFailure: err =>
          reject({
            reason: 'error',
            err: err
          }),
        mfaRequired: details =>
          reject({
            reason: 'mfaRequired',
            details: details
          }),
        customChallenge: details => reject({ reason: 'customChallenge', details: details })
      });
    });
  }

  changePassword(oldPassword: string, newPassword: string): Promise<{}> {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser === null) {
      return Promise.reject(new Error('No current session'));
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession(() => {
        cognitoUser.changePassword(oldPassword, newPassword, err => {
          if (err) {
            reject({ reason: 'error', err: err });
          } else {
            resolve(true);
          }
        });
      });
    });
  }

  forgotPassword(username: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool
    });

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: err => reject({ reason: 'error', err: err }),
        inputVerificationCode: data => resolve(data)
      });
    });
  }

  confirmPassword(username: string, verificationCode: string, newPassword: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => resolve(),
        onFailure: err => reject({ reason: 'error', err: err })
      });
    });
  }

  deleteUser(user): any {
    const cognitoUser = new CognitoUser({ Username: user, Pool: this.userPool });
    if (cognitoUser != null) {
      cognitoUser.getSession(function(err, session) {
        if (err) return;
      });
      return this.apiService.delete('auth/' + user);
    }
  }
}
