import { env } from './.env';
import { baseEnvironment } from './base-environment';

export const environment = Object.assign({}, baseEnvironment, {
  production: false,
  hmr: false,
  apiUrl: 'https://gamesa-beta.satcontrolapp.com/api/',
  webUrl: 'https://gamesa-beta.satcontrolapp.com/',
  version: env.npm_package_version + '-dev'
});
