import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUser,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {
  private userPool = null;
  private AmazonCognitoIdentity = require('amazon-cognito-identity-js');
  private AWS = require('aws-sdk');

  constructor(public http: HttpClient) {
    const poolData = {
      UserPoolId: environment.cognito.UserPoolId,
      ClientId: environment.cognito.ClientId
    };

    this.userPool = new CognitoUserPool(poolData);
  }

  createHttp(type, url, parameters = null): Observable<any> {
    if (type == 'get') {
      return this.http.get(environment.apiUrl + url + '/' + parameters);
    } else if (type == 'put') return this.http.put(environment.apiUrl + url + '/', parameters);
    else if (type == 'post') return this.http.post(environment.apiUrl + url, parameters);
    else if (type == 'delete') {
      return this.http.delete(environment.apiUrl + url + '/' + parameters);
    }
  }

  get(url: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http.get(environment.apiUrl + url, { observe: 'response', params: params });
  }

  put(url: string, body?: any): Observable<any> {
    return this.http.put(environment.apiUrl + url, body);
  }

  post(url: string, body: any): Observable<any> {
    return this.http.post(environment.apiUrl + url, body);
  }

  delete(url: string, params?: any): Observable<HttpResponse<any>> {
    return this.http.delete(environment.apiUrl + url, { observe: 'response', params: params });
  }

  getIdToken(): Promise<string> {
    return this.getCognitoUserSession()
      .then(session => this.recheckExpiration(session, 'id'))
      .then(session => session.getIdToken().getJwtToken());
  }

  recheckExpiration(session: CognitoUserSession, type: string): Promise<CognitoUserSession> {
    const token = type === 'id' ? session.getIdToken() : session.getAccessToken();
    const expiration = token.getExpiration();
    const offset = 300;
    if (expiration - new Date().getTime() / 1000 < offset) {
      return new Promise((resolve, reject) => {
        const cognitoUser = this.userPool.getCurrentUser();
        cognitoUser.refreshSession(session.getRefreshToken(), (err, new_session) => {
          if (err) {
            reject({ reason: 'error', err: err });
          } else {
            resolve(new_session);
          }
        });
      });
    }
    return Promise.resolve(session);
  }

  private getCognitoUserSession(): Promise<CognitoUserSession> {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser === null) {
      return Promise.reject(new Error('No current session'));
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject({ reason: 'error', err: err });
        } else {
          resolve(session);
        }
      });
    });
  }
}
