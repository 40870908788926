import { Component, OnInit } from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { Location } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { UserService } from './core/authentication/user.service';

import { environment } from '../environments/environment';

import * as moment from 'moment';

import es from '../assets/i18n/es.json';
import en from '../assets/i18n/en.json';
import { LanguageService } from './core/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  direction: string;
  available: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    private languageService: LanguageService
  ) {
    const sessionDirection = sessionStorage.getItem('portal-direction');
    this.direction = sessionDirection || 'ltr';

    this.languageService.init();
  }

  ngOnInit(): void {
    if (this.userService.isLoggedIn()) {
      this.setUserData();
    } else {
      this.available = true;
    }
  }

  navigateLogin() {
    this.router.navigate(['external/login']);
  }

  setUserData() {
    this.userService.getMyUserData().subscribe(
      data => {
        var user = data.body;
        environment.user.id = user.id_user;
        environment.user.role = user.role;
        environment.user.lang = user.language;
        environment.user.name = user.name;
        environment.user.user_code = user.user_code;
        environment.user.sms = user.sm;
        this.languageService.setSessionLanguage();

        this.available = true;
      },
      error => {
        this.userService.logout().then(() => this.router.navigate(['external/login']));
      }
    );
  }
}
