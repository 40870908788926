<a class="portal-user-menu-button" mat-button [mat-menu-trigger-for]="userDetailsMenu">
  <img class="portal-user-menu-button__username" src="../../../assets/images/gamesa.png" alt="Gamesa" />
</a>
<mat-menu #userDetailsMenu="matMenu" classList="portal-menu-no-padding">
  <div class="portal-user-menu-header" fxLayout="row" fxLayoutAlign=" center">
    <div class="portal-user-menu-header__details" fxLayout="column" fxLayoutAlign="center start" *ngIf="user!=null">
      <h2>{{ user.name }}</h2>
      <h3>{{ user.code }}</h3>
    </div>
  </div>
  <mat-nav-list class="portal-user-menu-list">
    <a mat-list-item (click)="onSignOut()">
      <mat-icon matListIcon>exit_to_app</mat-icon>
      <span>{{ 'SIGN_OUT' | translate }}</span>
    </a>
  </mat-nav-list>
</mat-menu>
