<mat-sidenav-container [dir]="layoutService.getDirection() | async" fxFlexFill>
  <mat-sidenav class="portal-toolbar-leftsidenav" #leftSidenav mode="over" opened="false">
    <portal-menu-sidenav class="portal-sidenav-theme" [sidenav]="leftSidenav"></portal-menu-sidenav>
  </mat-sidenav>
  <div fxLayout="column" fxFlexFill>
    <portal-top-horizontal-menu [leftSidenav]="leftSidenav"></portal-top-horizontal-menu>
    <div fxLayout="column" fxFlex="nogrow noshrink" class="portal-toolbar-content-wrapper portal-content-bg">
      <div fxFlexFill class="portal-toolbar-content" portalScrollTop>
        <router-outlet></router-outlet>
        <portal-layout-loader></portal-layout-loader>
      </div>
    </div>
  </div>
</mat-sidenav-container>
