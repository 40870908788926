<button mat-icon-button [matMenuTriggerFor]="languageMenu">
  <mat-icon>language</mat-icon>
</button>
<mat-menu #languageMenu="matMenu">
  <button
    mat-menu-item
    (click)="selectLanguage('en')"
    [disabled]="sessionLanguage === 'en' || sessionLanguage === null"
  >
    {{ 'ENGLISH' | translate }}
  </button>
  <button mat-menu-item (click)="selectLanguage('es')" [disabled]="sessionLanguage === 'es'">
    {{ 'SPANISH' | translate }}
  </button>
</mat-menu>
