<div fxLayout="column" fxFlexFill>
  <mat-toolbar class="portal-side-menu-top-toolbar">
    <mat-toolbar-row fxLayout="row">
      <img class="portal-toolbar-brand" src="assets/images/gamesa.png" alt="Portal" />
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="portal-sidnav-menu portal-hide-scrollbars" fxFlex="nogrow noshrink">
    <mat-nav-list>
      <a mat-list-item routerLink="/pages/home" routerLinkActive="portal-menu--active">
        <mat-icon matListIcon>home</mat-icon>
        <span matLine>{{ 'HOME' | translate }}</span>
      </a>
      <a mat-list-item routerLink="/pages/publications" routerLinkActive="portal-menu--active">
        <mat-icon matListIcon>notifications</mat-icon>
        <span matLine>{{ 'PUBLICATIONS' | translate }}</span>
      </a>
      <a mat-list-item routerLink="/pages/messages" routerLinkActive="portal-menu--active">
        <mat-icon matListIcon>chat_bubble</mat-icon>
        <span matLine>{{ 'MESSAGES' | translate }}</span>
      </a>
      <a mat-list-item routerLink="/pages/tbts" routerLinkActive="portal-menu--active">
        <mat-icon matListIcon>subject</mat-icon>
        <span matLine>TBT</span>
      </a>
      <a mat-list-item routerLink="/pages/work-permits" routerLinkActive="portal-menu--active"
        *ngIf="role == 'adv_admin' || role == 'root'">
        <mat-icon matListIcon>assignment</mat-icon>
        <span matLine>{{ 'WORK_PERMITS' | translate }}</span>
      </a>
      <a mat-list-item routerLink="/pages/checklists" routerLinkActive="portal-menu--active">
        <mat-icon matListIcon>check</mat-icon>
        <span matLine>{{ 'CHECKLISTS' | translate }}</span>
      </a>
      <mat-expansion-panel *ngIf="role == 'common_admin' || role == 'adv_admin' || role == 'root'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>priority_high</mat-icon>
            <h3>{{ 'ADMINISTRATION' | translate }}</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mat-nav-list>
            <a mat-list-item routerLink="/pages/emergency-files" routerLinkActive="portal-menu--active">
              <mat-icon matListIcon>description</mat-icon>
              <span matLine>{{ 'EMERGENCY_FILES' | translate }}</span>
            </a>
            <a mat-list-item routerLink="/pages/users" routerLinkActive="portal-menu--active">
              <mat-icon matListIcon>people</mat-icon>
              <span matLine>{{ 'USERS' | translate }}</span>
            </a>
            <a mat-list-item routerLink="/pages/work-centers" routerLinkActive="portal-menu--active">
              <mat-icon matListIcon>work</mat-icon>
              <span matLine>{{ 'WORK_CENTERS' | translate }}</span>
            </a>
            <a mat-list-item routerLink="/pages/checklists-template" routerLinkActive="portal-menu--active"
            *ngIf="role == 'adv_admin' || role == 'root'" >
              <mat-icon matListIcon>check</mat-icon>
              <span matLine>{{ 'CHECKLISTS_TEMPLATES' | translate }}</span>
            </a>
          </mat-nav-list>
        </div>
      </mat-expansion-panel>
    </mat-nav-list>
  </div>
  <mat-toolbar>
    <mat-toolbar-row fxLayout="row">
      <span fxFlex></span>
      <a mat-icon-button (click)="sidenav.close()">
        <mat-icon>chevron_left</mat-icon>
      </a>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
