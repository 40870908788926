import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { FlexLayoutModule } from '@angular/flex-layout';

// Modules
//import { DirectivesModule } from './directives/directives.module';
import { MaterialModule } from './material/material.module';

import { VersionNumberComponent } from './version-number/version-number.component';

@NgModule({
  imports: [
    CommonModule,
    //DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule.forChild()
  ],
  declarations: [VersionNumberComponent],
  exports: [
    CommonModule,
    //DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule,
    VersionNumberComponent
  ],
  providers: []
})
export class SharedModule {}
